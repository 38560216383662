<template>
  <div id="center">

    <!-- 导航 -->
    <HomePageTop />

    <div class="userCenter">
      <!-- 用户粗略信息 -->
      <!--            <div class="userindexhead">-->
      <!--              <userInfo :myPage="myPage"></userInfo>-->
      <!--            </div>-->
      <!-- 内容 -->
      <div class="userindexBody">
        <!--        左侧菜单及用户信息展示-->
        <div class="leftUserMessageContainer">
          <!--用户信息展示 -->
          <div class="userMessageHeaderContainer">
            <div class="userMessageContainerTop">
              <el-upload class="upload-demo" action="https://laravel.rakumart.us/api/common/uploadFile"
                :show-file-list="false" :on-success="handleAvatarSuccess" name="file" accept=".jpg,.jpeg,.png"
                :before-upload="befoUpload">
                <div class="showDow">{{ $fanyi('更改头像') }}</div>
                <img class="userHeadImg" :src="userInfo.head_img" alt="" />
              </el-upload>
              <!--              <img :src="userInfo.head_img" />-->
              <div class="userName">
                <div class="top">
                  <div>{{ $fanyi('你好') }},</div>
                  <el-popover placement="bottom" trigger="hover">
                    <div>{{ userInfo.user_name }}</div>
                    <div class="u-line" style="width: 150px" slot="reference">
                      {{ userInfo.user_name }}
                    </div>
                  </el-popover>
                </div>
                <div class="bottom">{{ $fanyi('这是你的控制面板') }}</div>
              </div>
            </div>
            <div class="userBalanceContainer flexAndCenter">
              <div class="userBalanceLeftContainer">
                <div>{{ $fanyi('账户余额') }}</div>
                <el-popover placement="bottom" trigger="hover">
                  <!-- <div style="font-size: 18px;font-weight: bold;color: #ff730b;">
                    <span style="font-size: 14px;font-weight: 500;color: #333; margin-right: 2px;" class="Pendingdeposit">
                      {{ $fanyi('待入金') }}:</span>
                    ${{ $fun.EURNumSegmentation(userInfo.balance_pay) }}
                  </div> -->
                  <div style="font-size: 18px;font-weight: bold;color: #ff730b;">
                    <span style="font-size: 14px;font-weight: 500;color: #333; margin-right: 2px;" class="drawing">
                      {{ $fanyi('提现中') }}:</span>
                    ${{ $fun.EURNumSegmentation(userInfo.balance_freezing) }}
                  </div>
                  <div class="u-line" slot="reference">
                    ${{ $fun.EURNumSegmentation(userInfo.balance_usable) }}
                  </div>
                </el-popover>
              </div>
              <div class="userBalanceRightContainer">
                <!-- <div class="Charge" @click="$router.push({ name: 'recharge' })">
                  {{ $fanyi('充值') }}
                </div> -->
                <div class="Cash" @click="$fun.toPage('/user/withdrawDeposit')">
                  {{ $fanyi('提现') }}
                </div>
              </div>
            </div>

            <div class="userMessageContainerBottom" @click="$fun.toPage('/user/inform')">
              <div class="userMessageBottomContainerLeft">
                <img src="../../assets/user-img/new-menu/notice.svg" style="width: 20px; height: 20px; margin-top: 5px" />
                <div style="font-size: 14px">{{ $fanyi('新消息') }}</div>
              </div>
              <div class="userMessageBottomContainerRight">
                {{ count }}
              </div>
            </div>
          </div>
          <!-- 菜单栏 -->
          <div class="menuBar">
            <div v-for="(item, index) in activeMenu" :key="item.id">
              <span :class="item.activeStatus === true ? 'activeMenuTitle' : 'menuTitle'
                " @click="updateSelectMenu(item.name, 1)" v-if="item.name === $fanyi('首页')"><img :src="item.activeStatus === true ? item.activeImgUrl : item.imgUrl
    " alt="" style="margin-left:5px" />
                {{ item.name }}</span>
              <span v-else :class="item.activeStatus === true ? 'activeMenuTitle' : 'menuTitle'
                " @click="updateSelectMenu(item.name, 2)"><img :src="item.activeStatus === true ? item.activeImgUrl : item.imgUrl
    " style="margin-left:5px" alt="" />
                {{ item.name }}
                <i :class="item.downIconStatus === true
                  ? 'el-submenu__icon-arrow el-icon-arrow-up'
                  : 'el-submenu__icon-arrow el-icon-arrow-down'
                  "></i></span>
              <!--              <span v-else class="menuTitle">{{ item.name }} <i class="el-submenu__icon-arrow el-icon-arrow-down"></i></span>-->
              <div class="menuChildrenList" v-if="item.downIconStatus === true && item.arr != undefined">
                <div v-for="(items, childrenIndex) in item.arr" :key="items.id" class="rowAndCenter"
                  @click="$router.push({ name: items.url, query: items.query })">
                  <div v-if="items.count != undefined" :class="items.activeStatus
                    ? 'activeMenuChildrenContainer rowAndCenter'
                    : 'noActiveMenuChildrenContainer rowAndCenter'
                    " @click="updateSelectChildrenMenu(index, childrenIndex)">
                    <div class="childrenName">{{ items.name }}</div>
                    <div :class="{ shopdianpu: items.id == '12' }" class="orderCountContainer">{{ items.count }}</div>
                  </div>
                  <div v-else :class="items.activeStatus
                    ? 'activeMenuChildrenContainer rowAndCenter'
                    : 'noActiveMenuChildrenContainer rowAndCenter'
                    " @click="updateSelectChildrenMenu(index, childrenIndex)">
                    <div class="childrenName">{{ items.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="contentBar">
          <div class="header">
            <div class="left">
              <div class="title">{{ $fanyi('账户信息摘要') }}</div>
              <div class="content">
                <div class="top">
                  <div class="left" style="margin-right: 20px" @click="

                    $fun.toPage('/user/order')
                  listenSignStatus(1)
                    ">
                    <div>
                      <countTo :startVal="countNumInfo.orderOffer" :endVal="newCountNumInfo.orderOffer" :duration="3000">
                      </countTo>
                    </div>
                    <div>{{ $fanyi('订单待处理') }}</div>
                  </div>
                  <div class="right" @click="
                    $fun.toPage('/user/order?status=warehouse')

                  listenSignStatus(1)
                    ">
                    <div>
                      <countTo :startVal="countNumInfo.upComplete" :endVal="newCountNumInfo.upComplete" :duration="3000">
                      </countTo>
                    </div>
                    <div style="line-height:13px">
                      {{ $fanyi('入库完了订单') }}
                    </div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="bottomLeft">
                    <div class="level">
                      <!--                      <div>{{ userInfo.level_name }}</div>-->
                      <img :src="levelImg" alt="" />
                    </div>
                    <div class="levelMessage">
                      <div class="levelMessageTop">
                        {{ $fanyi('佣金比例') }}
                        {{ (userInfo.service_rate * 100).toFixed(1) }}%
                      </div>
                      <div class="levelMessageCenter">
                        <div :style="'width:' + userInfo.next_level_difference"></div>
                      </div>
                      <el-link type="primary" @click="poundagePageJump">{{
                        $fanyi('佣金计算方法')
                      }}</el-link>
                    </div>
                  </div>
                  <div class="cuponesNumberContainer" @click="$fun.toPage('/user/coupon')">
                    <div>{{ $fanyi('优惠券') }}</div>
                    <div>{{ userInfo.coupon_number }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="title">{{ $fanyi('重点订单信息') }}</div>

              <div class="content">
                <div class="one" @click="

                  $fun.toPage('/user/order?status=obligation'), listenSignStatus(1)">
                  <div class=" one-left">
                    <span>
                      <countTo :startVal="countNumInfo.orderWaitPay" :endVal="newCountNumInfo.orderWaitPay"
                        :duration="3000"></countTo>
                    </span>
                    <span class="txt">{{ $fanyi('需要付款的订单') }}</span>
                  </div>
                  <div class="one-right">
                    <div class="icon"></div>
                    <div class="text">{{ $fanyi('管理订单') }}</div>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
                <div class="two" @click="
                  $fun.toPage('/user/wuliuStatus?status=Tobepaid'),
                  $store.commit(
                    'updateRouterQuery',
                    'Tobepaid'
                  )
                listenSignStatus(1)
                  ">
                  <div class="two-left">
                    <span>
                      <countTo :startVal="countNumInfo.porderOffer" :endVal="newCountNumInfo.porderOffer"
                        :duration="3000">
                      </countTo>
                    </span>
                    <span class="txt"> {{ $fanyi('待支付国际运费') }}</span>
                  </div>
                  <div class="two-right">
                    <div class="icon"></div>
                    <div class="text">{{ $fanyi('管理订单') }}</div>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
                <div class="there" @click="
                  $fun.toPage('/user/wuliuStatus?status=Usdeliveryunderway'),
                  $store.commit('updateRouterQuery', 'Usdeliveryunderway')
                listenSignStatus(1)
                  ">
                  <div class="there-left">
                    <span>
                      <countTo :startVal="countNumInfo.porderForeignTransport"
                        :endVal="newCountNumInfo.porderForeignTransport" :duration="3000"></countTo>
                    </span>
                    <span>
                      <div class="txt">
                        {{ $fanyi('美国运输中的订单') }}
                      </div>
                    </span>
                  </div>
                  <div class="there-right">
                    <div class="icon"></div>
                    <div class="text">{{ $fanyi('管理订单') }}</div>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <router-view :myPage="myPage" @signStatusVerdict="listenSignStatus"></router-view>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="1200px" :lock-scroll="false" :before-close="handleClose">
      <div class="InformBounced ql-editor">
        <h1>{{ readItem.title }}</h1>
        <p class="timeBox">{{ readItem.created_at }}</p>
        <div class="con" v-html="readItem.content"></div>
      </div>
      <button class="xioxibtn" @click="handleClose">{{ $fanyi('关闭') }}</button>
    </el-dialog>

    <Foot />
  </div>
</template>
<script>
import HomePageTop from '../../components/head/HomePageTop.vue'
import Foot from '../../components/foot/Foot.vue'
import countTo from 'vue-count-to'


import router from '@/router'

export default {
  data() {
    return {
      tableData: [],
      readItem: {},
      isRouterAlive: true,
      // activeMenu:[],
      UserInfo: {},
      count: 0,
      dialogVisible: false,  //公告·消息提示
      favoriteGoodsCount: 0, //收藏商品的数量
      warehouseOrderNum: 0,
      defaultOpeneds: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
      ],
      shopLoading: true,
      levelImg: '',
      activeMenu: [
        {
          id: '1',
          name: this.$fanyi('首页'),
          url: 'userIndex',
          activeStatus: false,
          downIconStatus: false,
          imgUrl: require('../../assets/user-img/new-menu/home_0.svg'),
          activeImgUrl: require('../../assets/user-img/new-menu/home_1.svg'),
        },
        {
          id: '2',
          name: this.$fanyi('订单管理'),
          activeStatus: false,
          downIconStatus: false,
          imgUrl: require('../../assets/user-img/new-menu/list_0.svg'),
          activeImgUrl: require('../../assets/user-img/new-menu/list_1.svg'),
          arr: [
            {
              id: '3',
              name: this.$fanyi('订单待处理'),
              url: 'userOrder',
              activeStatus: false,
              count: this.$store.state.leftSidebarNum[0].num,
            },
            {
              id: '4',
              name: this.$fanyi('问题产品'),
              url: 'issueOrder',
              count: this.$store.state.leftSidebarNum[1].num,
              activeStatus: false,
            },
            // {
            //   id: "5",
            //   name: this.$fanyi("订单到达RAKUMART仓库"),
            //   url: "warehouse",
            //   count: this.$store.state.leftSidebarNum[2].num,
            //   activeStatus: false,
            // },

            {
              id: '22',
              name: this.$fanyi('仓库'),
              url: 'Warehouse',
              activeStatus: false,
              count: this.$store.state.leftSidebarNum[5].num,
            },
            {
              id: '6',
              name: this.$fanyi('订单运输中'),
              url: 'wuliuStatus',
              count: this.$store.state.leftSidebarNum[3].num,
              activeStatus: false,
            },
            // {
            //   id: "7",
            //   name: this.$fanyi("已完成订单"),
            //   url: "internationalLogistics",
            //   count: this.$store.state.leftSidebarNum[4].num,
            //   activeStatus: false,
            // },
          ],
        },
        // {
        //   id: "8",
        //   name: this.$fanyi("发票"),
        //   activeStatus: false,
        //   downIconStatus: false,
        //   imgUrl: require("../../assets/user-img/menu/points_0.svg"),
        //   activeImgUrl: require("../../assets/user-img/menu/points_1.svg"),
        //   arr: [
        //     {
        //       id: "9",
        //       name: this.$fanyi("历史发票"),
        //       url: "invoiceHistory",
        //       count: this.$store.state.sidebarNumList["问题产品列表"]["未完成"],
        //       activeStatus: false,
        //     },
        //     {
        //       id: "10",
        //       name: this.$fanyi("发票信息"),
        //       url: "invoiceBillingData",
        //       activeStatus: false,
        //     },
        //   ],
        // },
        {
          id: '11',
          name: this.$fanyi('收藏夹'),
          activeStatus: false,
          downIconStatus: false,
          imgUrl: require('../../assets/user-img/new-menu/collection_0.svg'),
          activeImgUrl: require('../../assets/user-img/new-menu/collection_1.svg'),
          arr: [
            {
              id: '12',
              name: this.$fanyi('收藏商品'),
              url: 'collection',
              activeStatus: false,
              count: this.favoriteGoodsCount,
            },
            {
              id: '13',
              name: this.$fanyi('店铺收藏'),
              url: 'favoritec',
              activeStatus: false,
            },
            {
              id: '14',
              name: this.$fanyi('商品库'),
              url: 'commodity',
              activeStatus: false,
            },
          ],
        },
        {
          id: '15',
          name: this.$fanyi('个人资料'),
          activeStatus: false,
          downIconStatus: false,
          imgUrl: require('../../assets/user-img/new-menu/information_0.svg'),
          activeImgUrl: require('../../assets/user-img/new-menu/information_1.svg'),
          arr: [
            {
              id: '16',
              name: this.$fanyi('修改个人信息'),
              url: 'userDetail',
              activeStatus: false,
            },
            {
              id: '17',
              name: this.$fanyi('地址管理'),
              url: 'deliverySite',
              activeStatus: false,
            },
            {
              id: '18',
              name: this.$fanyi('密码修改与验证'),
              url: 'changepassworld',
              query: {
                type: 'password',
              },
              activeStatus: false,
            },
          ],
        },
        {
          id: '19',
          name: this.$fanyi('出入金记录'),
          activeStatus: false,
          downIconStatus: false,
          imgUrl: require('../../assets/user-img/new-menu/points_0.svg'),
          activeImgUrl: require('../../assets/user-img/new-menu/points_1.svg'),
          arr: [
            {
              id: '20',
              name: this.$fanyi('出入金记录'),
              url: 'churujinList',
              activeStatus: false,
            },
          ],
        },
        // {
        //   id: "21",
        //   name: this.$fanyi("仓库"),
        //   activeStatus: false,
        //   downIconStatus: false,
        //   imgUrl: require("../../assets/user-img/menu/information_0.svg"),
        //   activeImgUrl: require("../../assets/user-img/menu/information_1.svg"),
        //   arr: [
        //     {
        //       id: "22",
        //       name: this.$fanyi("仓库"),
        //       url: "oldWarehouse",
        //       activeStatus: false,
        //     },
        //   ],
        // },
      ],
      countNumInfo: {
        unDelivery: 0, //待处理订单
        upComplete: 0, //上架完了的订单
        orderOffer: 0, //待支付订单
        orderWaitPay: 0,
        porderOffer: 0, //待支付国际运费的配送单
        porderForeignTransport: 0, //西班牙运输中的配送单
      },
      newCountNumInfo: {
        unDelivery: 0, //待处理订单
        upComplete: 0, //上架完了的订单
        orderWaitPay: 0,
        orderOffer: 0, //待支付订单
        porderOffer: 0, //待支付国际运费的配送单
        porderForeignTransport: 0, //西班牙运输中的配送单
      },
      myPage: {
        balance: 0,
        order: {},
        pic: '',
        point: '',
        porder: {},
        user_id: '',
        user_name: '',
      },
    }
  },
  components: {
    Foot,
    HomePageTop,
    countTo,

  },
  computed: {
    activePage() {
      return this.$route.name
    },
    sidebarNumList() {
      return this.$store.state.sidebarNumList
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    ordersSumList() {
      return this.$store.state.ordersSumList
    },
  },
  created() {
    this.levelImg = require(`../../assets/foot-img/commission${this.userInfo.level_name}.svg`)
    this.getWarehouseOrderCountNum()
    this.$store.dispatch('getLeftSidebarNum')
    this.$store.dispatch('sidebarNumList')
    this.$store.dispatch('getOrdersSum')

    // this.getMyPage();
    this.getNoticeUnreadNoticeCount()
    this.getHeadCountNum()
    if (this.$route.name === 'userIndex') {
      this.activeMenu[0].activeStatus = true
    } else {
      for (let i = 0; i < this.activeMenu.length; i++) {
        if (this.activeMenu[i].arr != undefined) {
          for (let j = 0; j < this.activeMenu[i].arr.length; j++) {
            if (this.activeMenu[i].arr[j].url === this.$route.name) {
              this.activeMenu[i].activeStatus = true
              this.activeMenu[i].downIconStatus = true
              this.activeMenu[i].arr[j].activeStatus = true
              break
            }
          }
        }
      }
    }
  },
  mounted() { },
  methods: {
    listenSignStatus(msg) {
      this.getWarehouseOrderCountNum()
      this.$store.dispatch('getLeftSidebarNum')
      this.$store.dispatch('sidebarNumList')
      this.$store.dispatch('getOrdersSum')
      // this.getMyPage();
      this.getNoticeUnreadNoticeCount()
      this.getHeadCountNum()
      for (let i = 0; i < this.activeMenu.length; i++) {
        if (this.activeMenu[i].arr != undefined) {
          for (let j = 0; j < this.activeMenu[i].arr.length; j++) {
            if (this.activeMenu[i].arr[j].url === this.$route.name) {
              this.activeMenu[i].activeStatus = true
              this.activeMenu[i].downIconStatus = true
              this.activeMenu[i].arr.forEach((item, index) => {
                item.activeStatus = j === index
              })
              break
            } else {
              this.activeMenu[i].activeStatus = false
              this.activeMenu[i].downIconStatus = false
              this.activeMenu[i].arr[j].activeStatus = false
            }
          }
        } else {
          this.activeMenu[0].activeStatus = false
        }
      }
    },
    getNoticeUnreadNoticeCount() {
      //favoriteGoodsList
      this.$api
        .noticeUnreadNotice()
        .then((res) => {
          if (res.data.length > 0) {
            this.dialogVisible = true

            this.readItem = res.data[0]

          }
          this.count = res.data.length
          this.$api
            .favoriteGoodsList({
              page: 1,
              pageSize: 99999999999999999999999999999999999999999999999999999,
            })
            .then((res) => {
              this.favoriteGoodsCount = res.data.data.length

            })
            .catch((err) => { })
        })
        .catch((err) => { })
    },
    // 弹窗关闭
    handleClose() {


      this.$api.noticeReadNotice({ id: this.readItem.id }).then((res) => {
        this.dialogVisible = false
        this.getNoticeUnreadNoticeCount()
      });


    },
    // 获取仓库顶部数据的数量
    getWarehouseOrderCountNum() {
      this.$api.warehouseOrderCountNum().then((res) => {
        // this.tableTypeNum=res.data;
        res.data.forEach((item) => {
          switch (item.status) {
            case '全部状态':
              this.warehouseOrderNum = item.num
              break
          }
        })
      })
    },
    befoUpload(file) {
      const isJPG = file.type.indexOf('image') !== -1
      if (!isJPG) {
        this.$message.error(this.$fanyi('请上传图片'))
        return isJPG
      }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi('图片上传中'),
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, .7)',
      })
    },
    // 图片上传成功事件
    handleAvatarSuccess(res) {
      this.$api.userPortraitSave({ head_img: res.data }).then((res) => {
        if (res.code != 0) return
        this.$api.EuropegetUserInfo().then((res) => {
          this.$store.commit('userData', res.data)
          this.loading.close()
          this.$forceUpdate()
        })
      })
    },
    //获取个人中心看板统计头部数据
    getHeadCountNum() {
      this.$api
        .headCountNum()
        .then((res) => {
          this.newCountNumInfo = res.data
        })
        .catch((err) => { })
    },
    //更新选中的菜单样式
    updateSelectMenu(name, status) {

      if (name == '订单管理' || name == 'Order management') {

        this.activeMenu.forEach((item) => {
          if (item.name == '订单管理' || item.name == 'Order management') {
            item.arr.forEach((item1) => {

              if (item1.name == '订单待处理' || item1.name == 'All orders')
                item1.count = this.$store.state.leftSidebarNum[0].num
              if (item1.name == '问题产品' || item1.name == 'Faulty products')
                item1.count = this.$store.state.leftSidebarNum[1].num
              if (item1.name == '仓库' || item1.name == 'Warehouse')
                item1.count = this.$store.state.leftSidebarNum[5].num
              if (item1.name == '订单运输中' || item1.name == 'Order in transit')
                item1.count = this.$store.state.leftSidebarNum[3].num

            })
          }
        })
      }
      if (name == '收藏夹' || name == 'Favorites') {

        this.activeMenu.forEach((item) => {
          if (item.name == '收藏夹' || item.name == 'Favorites') {
            item.arr.forEach((item1) => {
              if (item1.name == '收藏商品' || item1.name == 'Favorite products')
                item1.count = this.$store.state.leftSidebarNum[6].num
              if (item1.name == '店铺收藏' || item1.name == 'Favorite shops')
                item1.count = this.$store.state.leftSidebarNum[7].num
              if (item1.name == '商品库' || item1.name == 'Product lists')
                item1.count = this.$store.state.leftSidebarNum[8].num
            })
          }
        })
      }
      this.activeMenu.forEach((item) => {
        if (
          item.name === name &&
          item.activeStatus === false &&
          item.downIconStatus === false
        ) {
          if (status === 1) {
            this.$router.push({ name: item.url })
          }
          item.activeStatus = true
          item.downIconStatus = true
        } else if (
          (item.name === name && item.activeStatus === true) ||
          (item.name === name &&
            item.activeStatus === false &&
            item.downIconStatus === true)
        ) {
          item.activeStatus = false
          item.downIconStatus = false
          if (item.arr != undefined) {
            item.arr.forEach((items) => {
              items.activeStatus = false
            })
          }
        } else if (item.name !== name && item.activeStatus === true) {
          item.activeStatus = false
          if (item.arr != undefined) {
            item.arr.forEach((items) => {
              items.activeStatus = false
            })
          }
        }
      })
    },
    //更新子菜单的选中项
    updateSelectChildrenMenu(index, index1) {
      // this.activeMenu[index].arr[index1].activeStatus=true;
      this.activeMenu.forEach((item, menuIndex) => {
        if (menuIndex === index) {
          this.activeMenu[menuIndex].activeStatus = true
          this.activeMenu[menuIndex].downIconStatus = true
          item.arr.forEach((items, menuSelectIndex) => {
            items.activeStatus = index1 === menuSelectIndex
          })
        } else {
          this.activeMenu[menuIndex].activeStatus = false
          if (item.arr != undefined) {
            item.arr.forEach((items) => {
              items.activeStatus = false
            })
          }
        }
      })
    },
    poundagePageJump() {
      let routeUrl = router.resolve({
        path: '/commission',
      })
      window.open(routeUrl.href)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../css/mixin';

#center {
  background: #f6f6f6;
}

// 消息提示弹框
.InformBounced {
  background: #fafafa;
  margin: 0 auto;
  border-radius: 10px;
  width: 1120px;
  height: 500px;
  padding: 20px 40px 40px;
  background-color: #fafafa;
  overflow: auto;

  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;

    margin-bottom: 10px;
    text-align: center;
  }

  .timeBox {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;
  }

  .con {
    height: 20px;
    font-size: 14px;
  }
}

/deep/.el-dialog {
  border-radius: 6px;

  .el-dialog__header {
    padding: 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ededed;
    height: 60px;
  }
}

.xioxibtn {
  margin: auto;
  display: flex;
  width: 162px;
  height: 48px;
  font-size: 16px;
  border-radius: 4px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #FF730B;
  margin-top: 10px;
  margin-bottom: 20px;

}

/deep/.el-dialog__body {
  padding: 0 30px !important;
}

.index {
  margin: 0 auto;
}

.rowAndCenter {
  display: flex;
  align-items: center;
}

.userCenter {
  width: 1400px;
  margin: 0 auto 30px;

  //头部
  .userindexhead {
    width: 100%;
    height: 140px;
    margin: 20px 0;
  }

  .u-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  //内容
  .userindexBody {
    display: flex;
    align-items: flex-start;
    width: 240px;

    .leftUserMessageContainer {
      .userMessageHeaderContainer {
        .userMessageContainerTop {
          display: flex;
          align-items: center;
          padding: 20px 27px;
          $userAvatar: 52px;

          .upload-demo {
            position: relative;

            &:hover .showDow {
              opacity: 1;
            }

            .showDow {
              background-color: rgba($color: #000000, $alpha: 0.5);
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              opacity: 0;
              transition: 0.3s;
              top: 0;
              left: -40px;
              border-radius: 50%;
              width: $userAvatar;
              margin: 0 30px 0 40px;
              height: $userAvatar;
              color: white;
              font-size: 12px;
            }
          }

          img {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            margin-right: 16px;
          }

          .userName {
            .top {
              font-size: 18px;
              padding: 0;
              display: flex;
              align-items: center;

              div:last-child {
                font-weight: bold;
              }
            }

            .bottom {
              font-size: 14px;
            }
          }
        }

        .userBalanceContainer {
          width: 320px;
          height: 92px;
          background: #ffffff;
          border: 1px solid #e2e2e2;
          margin: 0 0 20px 0;
          box-sizing: border-box;
          border-radius: 6px;
          border: 1px solid #e2e2e2;
          padding: 15px 10px 15px 20px;
          justify-content: space-between;

          .userBalanceLeftContainer {
            div:last-child {
              font-size: 24px;
              font-weight: 400;
              color: #222222;
              width: 200px;
            }
          }

          .userBalanceRightContainer {
            .Charge {
              min-width: 72px;
              height: 30px;
              background: #ff730b;
              border-radius: 4px;
              font-size: 14px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #ffffff;
              line-height: 30px;
              text-align: center;
              cursor: pointer;
              margin-bottom: 10px;
            }

            .Cash {


              border-radius: 4px;
              font-size: 14px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              min-width: 80px;
              height: 36px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #FF730B;
              color: #ff730b;
              line-height: 36px;
              text-align: center;
              cursor: pointer;

              &:hover {
                color: #fff;
                background-color: #ff730b;
              }
            }
          }
        }

        .userMessageContainerBottom {
          width: 320px;
          height: 56px;
          background: #fff;
          border-radius: 6px;
          border: 1px solid #e2e2e2;
          border: 1px solid #e2e2e2;
          margin-bottom: 29px;
          padding: 13px 10px 13px 19px;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .userMessageBottomContainerLeft {
            display: flex;
            align-items: center;

            div {
              margin-left: 11px;
            }

            img {
              width: 18px;
              height: 18px;
            }
          }

          .userMessageBottomContainerRight {
            width: 36px;
            height: 36px;
            background: #ff730b;

            border-radius: 6px;
            color: #fff;
            font-size: 16px;
            line-height: 36px;
            text-align: center;
          }
        }
      }

      .menuBar {
        width: 320px;
        margin-right: 20px;
        padding: 10px;

        width: 320px;
        min-height: 300px;
        background: #ffffff;
        border-radius: 6px;

        border: 1px solid #e2e2e2;
        // 菜单内容左间距
        $fontMarginLeft: 30px;

        >div {
          p {
            margin-bottom: -1px;
            width: 100%;
            height: 60px;
            font-size: 20px;
            line-height: 60px;

            font-weight: bold;
            padding-left: $fontMarginLeft;

            box-sizing: border-box;

            &.titleBox {
              position: relative;

              img {
                position: absolute;
                left: 12px;
                top: 22px;
                width: 16px;
                height: 16px;

                &.sec {
                  display: none;
                }
              }

              &:hover {
                img {
                  &.sec {
                    display: inline-block !important;
                  }
                }
              }
            }
          }

          span {
            display: block;
            width: 100%;
            height: 60px;
            font-size: 13px;
            line-height: 60px;
            padding-left: 20px;
            box-sizing: border-box;
            cursor: pointer;

            &.active {
              color: $subjectColor;
              background-color: #f0f1ff;
            }

            //&:hover {
            //  background-color: #f0f1ff;
            //}
          }

          .menuTitle {
            position: relative;
            color: #2f2f2f;
            font-size: 18px;
            display: flex;
            align-items: center;

            img {
              margin-right: 5px;
              width: 20px;
              height: 20px;
            }

            /deep/ .el-submenu__icon-arrow,
            /deep/ .el-icon-arrow-down {
              font-size: 12px;
              font-weight: bold;
            }
          }

          .activeMenuTitle {
            position: relative;
            background: #ff730b;
            border-radius: 6px;
            border: 1px solid #ff730b;
            color: #fff;
            background: #ff730b;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            display: flex;
            align-items: center;
            font-size: 18px;

            ///deep/ .el-icon-arrow-right {
            //  font-size: 16px;
            //  font-weight: bold;
            //  color: #fff;
            //  transform: rotateZ(90deg);
            //}
            img {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }

            /deep/ .el-icon-arrow-down,
            /deep/ .el-icon-arrow-up {
              font-size: 12px;
              font-weight: bold;
              color: #fff;
            }
          }

          .menuChildrenList {
            .noActiveMenuChildrenContainer {
              justify-content: space-between;
              margin: 13px 10px 0;
              padding-right: 7px;
              padding-left: 39px;
              cursor: pointer;
              width: 300px;
              height: 45px;

              .childrenName {
                color: #535353;
              }

              .orderCountContainer {
                width: 30px;
                height: 30px;
                border-radius: 4px;
                background-color: #fff;
                font-size: 14px;
                font-family: Roboto-Bold, Roboto;
                font-weight: bold;
                color: #222222;
                line-height: 30px;
                text-align: center;
                font-size: 14px;
              }
            }

            .activeMenuChildrenContainer {
              justify-content: space-between;
              margin: 13px 10px 0;
              padding-right: 7px;
              padding-left: 39px;
              padding-top: 10px;
              padding-bottom: 10px;
              cursor: pointer;
              width: 300px;
              min-height: 45px;
              background: #fff2e8;
              border-radius: 6px;
              color: #fff;

              .childrenName {
                font-size: 16px;
                font-family: Roboto-Medium, Roboto;
                font-weight: 500;
                color: #ff730b;
              }

              .orderCountContainer {
                width: 30px;
                height: 30px;
                background: #ff730b;
                border-radius: 4px;
                border-radius: 4px;
                color: #fff;
                line-height: 30px;
                text-align: center;
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    .contentBar {
      width: 1140px;

      //background-color: white;
      .header {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .left {
          width: 400px;
          height: 238px;
          background: #ffffff;
          border-radius: 6px;
          border: 1px solid #e2e2e2;
          padding: 20px 30px 20px 30px;
          margin-right: 20px;

          .title {
            font-size: 14px;
            font-weight: 600;
            color: #222;
            // font-family: Roboto-Medium, Roboto;
          }

          .content {
            margin-top: 20px;

            .top {
              display: flex;
              align-items: center;

              .left,
              .right {
                width: 160px;
                height: 80px;
                cursor: pointer;
                color: #fff;
                padding: 10px 0 0 20px;
                box-sizing: border-box;

                border-radius: 6px;

                div:first-child {
                  font-weight: 600;
                  font-size: 24px;
                  margin-bottom: 6px;
                }

                div:last-child {
                  font-weight: 400;
                  font-size: 14px;
                }
              }

              .left {
                background: #00c18b;

                border: none;
              }

              .right {
                background: #698cff;

                border: none;
              }
            }

            .bottom {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 20px;

              .bottomLeft {
                display: flex;
                align-items: center;

                .level {
                  width: 26px;
                  height: 26px;
                  //background: #d6d6d6;
                  //border: 1px solid #e3e3e3;
                  border-radius: 2px;
                  color: #fff;
                  text-align: center;
                  line-height: 24px;
                  font-size: 12px;
                  margin-right: 10px;

                  //div {
                  //  transform: rotate(-45deg);
                  //}
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .levelMessage {
                  .levelMessageTop {
                    font-size: 14px;
                    margin-bottom: 10px;
                  }

                  .levelMessageCenter {
                    width: 221px;
                    height: 4px;
                    margin-bottom: 5px;
                    background-color: #f0f0f0;

                    div {
                      width: 55%;
                      height: 100%;
                      background: #ff730b;
                    }
                  }

                  /deep/ .el-link {
                    font-size: 12px;
                    color: #ff730b !important;
                  }

                  /deep/ .el-link.el-link--primary:after {
                    border-color: #ff730b !important;
                  }
                }
              }

              .cuponesNumberContainer {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                cursor: pointer;

                div:first-child {
                  font-size: 14px;
                  margin-bottom: 8px;
                }

                div:last-child {
                  font-weight: 600;
                  font-size: 18px;
                }
              }
            }
          }
        }

        .right {
          width: 640px;
          height: 238px;
          background: #ffffff;

          padding: 20px 30px;
          box-sizing: border-box;
          border-radius: 6px;
          border: 1px solid #e2e2e2;

          .title {
            font-weight: 600;
            font-size: 14px;
            color: #222222;
            margin-bottom: 10px;
          }

          .content {

            // display: flex;
            // align-items: center;
            .one {
              width: 580px;
              height: 48px;
              background: #fff2e8;
              border-radius: 6px;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              padding-left: 8px;
              padding-right: 20px;
              justify-content: space-between;

              .one-left {
                display: flex;
                align-items: center;
                height: 100%;

                .txt {
                  font-size: 14px;
                  display: inline-block;
                  font-family: Roboto-Regular, Roboto;
                  font-weight: 400;
                  color: #222222;
                  margin-left: 17px;

                  &:hover {
                    cursor: pointer;
                  }
                }

                span {
                  &:first-child {
                    width: 32px;
                    height: 32px;
                    background: #ff730b;
                    border-radius: 4px;
                    background: #ff730b;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-family: Roboto-Bold, Roboto;
                    font-weight: bold;
                    color: #ffffff;
                  }
                }
              }

              .one-right {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon {
                  width: 8px;
                  height: 8px;
                  background: #ff730b;
                  border-radius: 50%;
                  margin-top: 3px;
                }

                .text {
                  margin-left: 6px;
                  margin-right: 5px;
                  font-size: 14px;
                  font-family: Roboto-Regular, Roboto;
                  font-weight: 400;
                  color: #222222;

                  &:hover {
                    cursor: pointer;
                  }
                }

                /deep/ .el-icon-arrow-right {
                  margin-top: 2px;
                  color: #000000;
                  font-size: 14px;
                }
              }
            }

            .two {
              width: 580px;
              height: 48px;
              background: #fbe5e3;
              border-radius: 6px;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              padding-left: 8px;
              padding-right: 20px;
              justify-content: space-between;

              .two-left {
                display: flex;
                align-items: center;
                height: 100%;

                .txt {
                  font-size: 14px;
                  display: inline-block;
                  font-family: Roboto-Regular, Roboto;
                  font-weight: 400;
                  color: #222222;
                  margin-left: 17px;

                  &:hover {
                    cursor: pointer;
                  }
                }

                span {
                  &:first-child {
                    width: 32px;
                    height: 32px;
                    background: #e74c3c;
                    border-radius: 4px;

                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-family: Roboto-Bold, Roboto;
                    font-weight: bold;
                    color: #ffffff;
                  }
                }
              }

              .two-right {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon {
                  width: 8px;
                  height: 8px;
                  background: #e74c3c;
                  border-radius: 50%;
                  margin-top: 3px;
                }

                .text {
                  margin-left: 6px;
                  margin-right: 5px;
                  font-size: 14px;
                  font-family: Roboto-Regular, Roboto;
                  font-weight: 400;
                  color: #222222;

                  &:hover {
                    cursor: pointer;
                  }
                }

                /deep/ .el-icon-arrow-right {
                  margin-top: 2px;
                  color: #000000;
                  font-size: 14px;
                }
              }
            }

            .there {
              width: 580px;
              height: 48px;
              background: #d8fbf1;
              border-radius: 6px;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              padding-left: 8px;
              padding-right: 20px;
              justify-content: space-between;

              .there-left {
                display: flex;
                align-items: center;
                height: 100%;

                .txt {
                  font-size: 14px;
                  display: inline-block;
                  font-family: Roboto-Regular, Roboto;
                  font-weight: 400;
                  color: #222222;
                  margin-left: 17px;

                  &:hover {
                    cursor: pointer;
                  }
                }

                span {
                  &:first-child {
                    width: 32px;
                    height: 32px;

                    border-radius: 4px;
                    background: #00c18b;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-family: Roboto-Bold, Roboto;
                    font-weight: bold;
                    color: #ffffff;
                  }
                }
              }

              .there-right {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon {
                  width: 8px;
                  height: 8px;
                  background: #00c18b;
                  border-radius: 50%;
                  margin-top: 3px;
                }

                .text {
                  margin-left: 6px;
                  margin-right: 5px;
                  font-size: 14px;
                  font-family: Roboto-Regular, Roboto;
                  font-weight: 400;
                  color: #222222;

                  &:hover {
                    cursor: pointer;
                  }
                }

                /deep/ .el-icon-arrow-right {
                  margin-top: 2px;
                  color: #000000;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
